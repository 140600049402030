<template>
  <router-view />
</template>


<style>
html,
body,
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
  margin: 0;
  padding: 0;
  cursor: default;
  overflow-x: hidden;

  --van-tabbar-height: 60px;
  --van-tabbar-item-icon-size: 20px;
  --van-tabbar-item-font-size: 14px;
  --van-card-price-color: #cfac6b;
  --van-card-price-color-light: #d5ba80;
  --van-submit-bar-height: var(--van-tabbar-height);
  --van-submit-bar-button-width: 130px;
  --van-submit-bar-button-height: 46px;
  --van-submit-bar-font-size: 16px;
  --van-dialog-confirm-button-text-color: var(--van-card-price-color);

  --van-overlay-z-index: 1000;
}

.van-submit-bar__bar {
  --van-button-normal-font-size: 16px;
  --van-button-round-radius: 8px;
}

.router-view {
  width: 100%;
  height: auto;
}

.van-swipe-item img {
  width: 100%;
}

@media (max-width: 678px) {
  .pc-my-page {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 0;
  }
}

@media (min-width: 679px) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #333;
  }

  .van-overflow-hidden {
    overflow: auto !important;
  }
  .price::before {
    content: var(--aog-currency);
    font-size: 70%;
  }
  .pc-my-page {
    display: flex;
    padding: calc(100vw / 1920 * 60) calc(100vw / 1920 * 360)
      calc(100vw / 1920 * 70) calc(100vw / 1920 * 360);
    background-color: #f7f7f7;
  }
  .pc-my-page .main {
    width: 0;
    flex-grow: 1;
    background-color: #fff;
    padding-bottom: 30px;
  }

  .pc-my-page .main .big-title {
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }

  .pc-my-page .main .big-title::before {
    content: "";
    display: block;
    width: 4px;
    height: 20px;
    background-color: var(--van-card-price-color);
    border-radius: 2px;
    margin-right: 6px;
  }

  .pc-my-page .main .control {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    --el-color-primary: var(--van-card-price-color);
    margin-bottom: 20px;
  }

  .pc-my-page .main .logs {
    width: 100%;
    --el-table-header-text-color: #000;
  }

  .pc-my-page .main .el-pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    --el-fill-color-blank: rgba(0, 0, 0, 0);
    --el-pagination-border-radius: 50%;
    --el-pagination-hover-color: var(--van-card-price-color);
    --el-pagination-button-height: 22px;
    --el-pagination-button-width: 22px;
    margin-top: 20px;
  }

  .pc-my-page .main .el-pagination .el-pager li {
    margin: 0 3px;
  }

  .pc-my-page .main .el-pagination .el-pager li.is-active {
    background-color: #000;
    color: #fff;
  }

  .pc-my-page .main .credit-info {
    display: flex;
    height: calc(100vw / 1920 * 170);
    margin-bottom: calc(100vw / 1920 * 30);
    background: url("./assets/images/member_bg.jpg") no-repeat 50% 0;
    background-size: cover;
  }

  .pc-my-page .main .credit-info .item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33.33%;
    flex-grow: 1;
    border-left: 2px solid #fff;
    /*background-color: #f7f7f7;*/
    font-size: 16px;
    line-height: 32px;
    color: #fff;
  }

  .pc-my-page .main .credit-info .item:first-child {
    border-left: 0;
  }

  .pc-my-page .main .credit-info .item .num {
    font-size: 30px;
    font-weight: bold;
  }

  .pc-my-page .main .credit-menu li {
    width: 100%;
    box-sizing: border-box;
    padding: 0 var(--aog-credit-menu-bottom-margin);
    margin-bottom: var(--aog-credit-menu-bottom-margin);
    background-color: #fff;
    border-radius: 10px;
  }

  .pc-my-page .main .credit-menu li a {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 10px;
    color: #000;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
  }

  .pc-my-page .main .credit-menu li a:hover {
    background-color: #000;
    color: #fff;
  }

  .pc-my-page .main .credit-menu li a .title {
    display: inline-flex;
    align-items: center;
    flex-grow: 1;
    width: 0;
  }

  .pc-my-page .main .credit-menu li a .title .iconfont {
    margin-right: 6px;
    font-size: 20px;
  }

  .pc-my-page .main .credit-menu li a .price {
    font-size: 20px;
  }

  .pc-my-page .main .credit-menu li a::after {
    content: "\e68b";
    font-family: "iconfont";
    margin-left: 10px;
  }

  .el-cascader-node.in-active-path,
  .el-cascader-node.is-active,
  .el-cascader-node.is-selectable.in-checked-path {
    --el-cascader-menu-selected-text-color: var(--van-card-price-color);
  }

  .el-select,
  .el-select-dropdown__item,
  .el-textarea {
    --el-color-primary: var(--van-card-price-color);
  }
}
</style>
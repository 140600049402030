import axios from "axios";
import { getCookie } from "./index";
import actions from "../actions";

export async function checkLogin() {
    return new Promise((resolve, reject) => {
        let agentid = getCookie('agentloginid');
        let agenttoken = getCookie('agentlogintoken');

        if (!agentid || !agentid) {
            resolve('NOTLOGIN');
        }

        axios.post(actions.checklogin, {
            agentid: agentid,
            agenttoken: agenttoken,
        }).then((response) => {
            let retdata = response.data;
            if (!retdata || retdata.retcode != 'OK') {
                resolve('CHECKLOGINFAILED');
            } else if (retdata.repair > 0 || retdata.showrepair) {
                resolve(retdata);
            }
            resolve('OK');
        }).catch(error => {
            reject(error);
        });
    });
}

export function getLoginTokens() {
    let agentid = getCookie('agentloginid');
    let agenttoken = getCookie('agentlogintoken');

    return {
        agentid: agentid,
        agenttoken: agenttoken
    };
}
import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import actions from './actions.js'
import { getCookie, setCookie, formatMoney } from './util/index.js'
import { checkLogin } from './util/agent';
import { showDialog } from 'vant';

import ElementPlus from 'element-plus'
import zhcn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/theme-chalk/index.css'
import 'element-plus/theme-chalk/display.css'

import { Button, Image as VanImage, Cell, NoticeBar, Notify, Popover, Overlay, Loading, Step, Steps, Uploader, Form, Field, CellGroup, Lazyload, Picker, Popup, Tabbar, TabbarItem, Swipe, SwipeItem, PullRefresh, List, Card, Tag, Stepper, Sidebar, SidebarItem, Search, Dialog, Toast, ImagePreview, SubmitBar, Checkbox, CheckboxGroup, SwipeCell, Empty, RadioGroup, Radio, Switch, DropdownMenu, DropdownItem, DatePicker, Cascader } from 'vant'
import 'vant/lib/index.css'

import './assets/fonts/font_4119788_zpb8capewel/iconfont.css'

const app = createApp(App)

const LANGUAGES = {
    en: require('@/assets/locale/lang/en-us.json'),
    zh: require('@/assets/locale/lang/zh-cn.json'),
    id: require('@/assets/locale/lang/id-id.json')
};

app.config.globalProperties.actions = actions;
app.config.globalProperties.apibase = '/api/'
app.config.globalProperties.uploadbase = '/'; // http://distrimall.wbaohe.com/
app.config.globalProperties.langcode = 'id';
app.config.globalProperties.pagedata = {};
app.config.globalProperties.rootApp = app;

let langcode = getCookie('lh_aog_langcode');
if (!langcode) {
    langcode = app.config.globalProperties.langcode;
    setCookie("lh_aog_langcode", langcode);
} else {
    app.config.globalProperties.langcode = langcode;
}

app.config.globalProperties.LANGUAGES = LANGUAGES;
app.config.globalProperties.LANG = LANGUAGES[langcode];


app.use(router)

router.beforeEach((to, from, next) => {
    console.log('to => ', to);
    console.log('from => ', from);
    console.log('next => ', next);

    if (to.name == 'appflow') {
        next();
    } else {
        checkLogin().then((data) => {
            let SYSLANG = app.config.globalProperties.LANG.system;
            console.log('showrepair => ', data);
            if (data.showrepair) {
                if (to.name == 'home') {
                    showDialog({
                        title: SYSLANG.dialogtitle,
                        message: SYSLANG.到期需补缴,
                    }).then(() => {
                        next();
                    });
                }
                else {
                    next();
                }
            }
            else if (data.repair && data.repair * 1 > 0) {
                if (data.hasrepair) {
                    showDialog({
                        title: SYSLANG.dialogtitle,
                        message: SYSLANG.您补缴的平台服务费正在审核,
                    }).then(() => {
                        if (to.name == 'Login') {
                            setCookie("agentloginid", "");
                            setCookie("agentlogintoken", "");
                            next();
                        }
                    });
                }
                else if (to.name != 'rechargecredit') {
                    // 弹窗提示补缴平台服务费
                    showDialog({
                        title: SYSLANG.dialogtitle,
                        message: SYSLANG.您上一年度平台服务费未满足减免条件 + formatMoney(data.repair),
                    }).then(() => {
                        // on close
                        router.push("/credit/recharge/?op=repair");
                    });
                }
                else {
                    next();
                }
            }
            else if (data != 'OK') {
                setCookie("agentloginid", "");
                setCookie("agentlogintoken", "");

                if (to.name != 'Login' && to.name != 'register') {
                    router.push("/login");
                }
                else {
                    next();
                }
            } else {
                next();
            }
        }).catch((error) => {
            console.log('router.index.routerCheckLogin.error', error);
            next();
        });
    }
})

app.provide('$axios', axios)

app.use(ElementPlus, {
    locale: zhcn
})

axios.interceptors.request.use(function (config) {
    let agentlogintoken = getCookie('agentlogintoken');
    let agentloginid = getCookie('agentloginid');
    let lc = getCookie('lh_aog_langcode');

    console.log('lc', lc);

    if (agentlogintoken && agentloginid) {
        config.headers = config.headers ?? {};
        //config.headers.Authorization = config.headers.Authorization??{};
        config.headers['agentlogintoken'] = agentlogintoken;
        config.headers['agentloginid'] = agentloginid;
        config.headers['langcode'] = lc;
    }

    console.log(config);

    return config;
}, function (error) {
    console.info("error: ");
    console.info(error);
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    if (response.data && response.data.retcode == 'CHECKLOGINFAILED') {
        router.push('/login');
    }
    return response;
}, function (error) {
    console.log(error);
});

app.use(VueAxios, axios);

app.use(Button)
    .use(VanImage)
    .use(Lazyload, {
        lazyComponent: true,
    })
    .use(Form)
    .use(Field)
    .use(CellGroup)
    .use(Picker)
    .use(Popup)
    .use(Tabbar)
    .use(TabbarItem)
    .use(Swipe)
    .use(SwipeItem)
    .use(PullRefresh)
    .use(List)
    .use(Card)
    .use(Tag)
    .use(Stepper)
    .use(Sidebar)
    .use(SidebarItem)
    .use(Search)
    .use(Dialog)
    .use(Toast)
    .use(ImagePreview)
    .use(SubmitBar)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(SwipeCell)
    .use(Empty)
    .use(RadioGroup)
    .use(Radio)
    .use(Switch)
    .use(Step)
    .use(Steps)
    .use(Uploader)
    .use(NoticeBar)
    .use(Uploader)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(DatePicker)
    .use(Notify)
    .use(Overlay)
    .use(Loading)
    .use(Popover)
    .use(Cascader)
    .use(Cell)

app.mount('#app')
export default {
    mobile_prefix: '/api/index.php?entry=getmobileprefix',

    goods_list: '/api/index.php?entry=goods',
    goods_cates: '/api/index.php?entry=goods&op=category',
    goods_detail: '/api/index.php?entry=goods&op=detail&goodsid=',
    goods_props: '/api/index.php?entry=goods&op=getprops&goodsid=',

    cart: '/api/index.php?entry=cart',
    cart_add: '/api/index.php?entry=cart&op=add',
    cart_update: '/api/index.php?entry=cart&op=update',
    cart_delele: '/api/index.php?entry=cart&op=delete',

    member: '/api/index.php?entry=member',

    order: '/api/index.php?entry=order',
    order_nums: '/api/index.php?entry=order&op=nums', // 订单数量
    order_order: '/api/index.php?entry=order&op=order', // 直接下单
    order_orderc: '/api/index.php?entry=order&op=orderc', // 从购物车下单
    order_placeorder: '/api/index.php?entry=order&op=placeorder', // 保存订单
    order_shippingfee: '/api/index.php?entry=order&op=shippingfee', // 计算运费
    order_detail: '/api/index.php?entry=order&op=detail&orderid=', // 订单详情
    order_pay: '/api/index.php?entry=order&op=gopay&orderid=', // 订单支付
    order_receipt: '/api/index.php?entry=order&op=receipt&orderid=', // 确认收货
    order_timeout: '/api/index.php?entry=order&op=paytimeout&orderid=', // 支付超时
    order_cancel: '/api/index.php?entry=order&op=cancel&orderid=', // 取消订单
    order_delete: '/api/index.php?entry=order&op=delete&orderid=', // 删除订单

    address: '/api/index.php?entry=address',
    address_detail: '/api/index.php?entry=address&op=detail&addrid=',
    address_setdefault: '/api/index.php?entry=address&op=setdefault&addrid=',
    address_area: '/api/index.php?entry=address&op=area&countryid=',
    address_delete: '/api/index.php?entry=address&op=delete&addrid=',
    address_save: '/api/index.php?entry=address&op=save',
    address_country: '/api/index.php?entry=address&op=country',

    credit: '/api/index.php?entry=credit',
    recharge: '/api/index.php?entry=credit&op=recharge',
    credit_logs: '/api/index.php?entry=credit&op=logs',
    credit_recharge_logs: '/api/index.php?entry=credit&op=rechargelogs',

    dologin: '/api/index.php?entry=agent&op=dologin',
    checklogin: '/api/index.php?entry=agent&op=checklogin',
    doagentregist: '/api/index.php?entry=agent&op=doagentregist',
    getagentinfo: '/api/index.php?entry=agent&op=getagentinfo',
    saveidprofile: '/api/index.php?entry=agent&op=saveidprofile',
    initcharge: '/api/index.php?entry=agent&op=initcharge',
    acontacts: '/api/index.php?entry=agent&op=acontacts',
    agrouplist: '/api/index.php?entry=agent&op=agrouplist',
    getpfaccount: '/api/index.php?entry=agent&op=getpfaccount',
    getaglvinfo: '/api/index.php?entry=agent&op=getaglvinfo',

    income: '/api/index.php?entry=income',
    income_withdraw: '/api/index.php?entry=income&op=withdraw',
    income_withdraw_logs: '/api/index.php?entry=income&op=withdrawlogs',
    income_toberecorded: '/api/index.php?entry=income&op=toberecorded',
    income_account: '/api/index.php?entry=income&op=account',
    income_account_detail: '/api/index.php?entry=income&op=account&subop=detail&accountid=',
    income_account_save: '/api/index.php?entry=income&op=account&subop=save',
    income_account_delete: '/api/index.php?entry=income&op=account&subop=delete&accountid=',
    income_logs: '/api/index.php?entry=income&op=logs',

    announce: '/api/index.php?entry=showpage&op=announce',
    announcedetail: '/api/index.php?entry=showpage&op=announcedetail&id=',

    invite: '/api/index.php?entry=invite',
    invite_user: '/api/index.php?entry=invite&op=getlist'
}
//import axios from 'axios'
import { createRouter, createWebHashHistory } from 'vue-router'
//import actions from '../actions'
/*import { setCookie } from '../util';
import { checkLogin } from '../util/agent';
import { showDialog } from 'vant';*/

const routes = [
    {
        path: '/login',
        name: 'Login',
        meta: { title: "用户登录" },
        component: () => import('../views/Login.vue')
    },
    {
        path: '/register',
        name: 'register',
        meta: { title: "注册" },
        component: () => import('../views/Register.vue')
    },
    {
        path: '/appflow',
        name: 'appflow',
        meta: { title: "申请流程" },
        component: () => import('../views/ApplicationFlow.vue')
    },
    {
        path: '/forget',
        meta: { title: "忘记密码" },
        component: () => import('../views/Forget.vue')
    },
    {
        path: '/agentgroup',
        name: 'agentgroup',
        icon: 'icon-home',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'agentgroup',
                icon: 'icon-home',
                title: '经销商中心',
                path: '/agentgroup',
                meta: { title: '经销商中心' },
                component: () => import('../views/AgentGroup.vue')
            },
            {
                name: 'agrouplist',
                icon: 'icon-home',
                title: '经销商列表',
                path: '/agentgroup/list',
                meta: { title: '经销商列表' },
                component: () => import('../views/AGroupList.vue')
            },
            {
                name: 'distributors',
                icon: 'icon-home',
                title: '分销商',
                path: '/agentgroup/distributors',
                meta: { title: '分销商' },
                component: () => import('../views/AgentDistributors.vue')
            },
            {
                name: 'retailers',
                icon: 'icon-home',
                title: '零售商',
                path: '/agentgroup/retailers',
                meta: { title: '零售商' },
                component: () => import('../views/AgentRetailers.vue')
            },
            {
                name: 'invite',
                icon: 'icon-home',
                title: '邀请注册',
                path: '/agentgroup/invite',
                meta: { title: '邀请注册' },
                component: () => import('../views/AgentInvite.vue')
            },
        ]
    },
    {
        path: '/',
        name: 'home',
        icon: 'icon-home',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'home',
                ismainmenu: true,
                showmainmenu: true,
                icon: 'icon-home',
                title: '首页',
                path: '/',
                meta: { title: '首页' },
                component: () => import('../views/Home.vue')
            },
        ]
    },
    {
        path: '/product',
        name: 'productcenter',
        icon: 'icon-wenxiong',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'product',
                ismainmenu: true,
                showmainmenu: true,
                icon: 'icon-wenxiong',
                title: '产品中心',
                path: '/product',
                meta: { title: '产品中心' },
                component: () => import('../views/Product.vue')
            },
            {
                name: 'productdetail',
                icon: 'icon-wenxiong',
                title: '产品详情',
                path: '/product/detail',
                meta: { title: '产品详情' },
                component: () => import('../views/ProductDetail.vue')
            },
            {
                name: 'search',
                icon: 'icon-wenxiong',
                title: '搜索',
                path: '/product/search',
                meta: { title: '搜索' },
                component: () => import('../views/Search.vue')
            },
        ]
    },
    {
        path: '/cart',
        name: 'cart',
        icon: 'icon-cart-02',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'cart',
                ismainmenu: true,
                showmainmenu: true,
                icon: 'icon-cart-02',
                title: '购物车',
                path: '/cart',
                meta: { title: '购物车' },
                component: () => import('../views/Cart.vue')
            },
        ]
    },
    {
        path: '/my',
        name: 'my',
        icon: 'icon-my',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'my',
                ismainmenu: true,
                showmainmenu: true,
                icon: 'icon-my',
                title: '我的',
                path: '/my',
                meta: { title: '我的' },
                component: () => import('../views/My.vue')
            },
            {
                name: 'acontacts',
                title: '团队成员',
                path: '/acontacts',
                meta: { title: '团队成员' },
                component: () => import('../views/AgentContacts.vue')
            },
            {
                name: 'viewpdf',
                title: '授权证书',
                path: '/viewpdf',
                meta: { title: '授权证书' },
                component: () => import('../views/ViewPDF.vue')
            },
            {
                name: 'profile',
                title: '个人资料',
                path: '/profile',
                meta: { title: '个人资料' },
                component: () => import('../views/MyProfile.vue')
            },
        ]
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'orderlist',
                title: '订单列表',
                path: '/order',
                meta: { title: '订单列表' },
                component: () => import('../views/Order.vue')
            },
            {
                name: 'placeorder',
                title: '提交订单',
                path: '/order/placeorder',
                meta: { title: '提交订单' },
                component: () => import('../views/OrderPre.vue')
            },
            {
                name: 'orderdetail',
                title: '订单详情',
                path: '/order/detail',
                meta: { title: '订单详情' },
                component: () => import('../views/OrderDetail.vue')
            }
        ]
    },
    {
        path: '/address',
        name: 'address',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'addresslist',
                title: '地址列表',
                path: '/address',
                meta: { title: '地址列表' },
                component: () => import('../views/Address.vue')
            },
            {
                name: 'addressdetail',
                title: '地址详情',
                path: '/address/detail',
                meta: { title: '地址详情' },
                component: () => import('../views/AddressDetail.vue')
            }
        ]
    },
    {
        path: '/credit',
        name: 'recharge',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'rechargecredit',
                title: '充值',
                path: '/credit/recharge',
                meta: { title: '充值' },
                component: () => import('../views/CreditRecharge.vue')
            },
            {
                name: 'rechargelogs',
                title: '货款明细',
                path: '/credit/logs',
                meta: { title: '货款明细' },
                component: () => import('../views/CreditLogs.vue')
            },
            {
                name: 'rechargelogs2',
                title: '充值记录',
                path: '/credit/rechargelogs',
                meta: { title: '充值记录' },
                component: () => import('../views/CreditRechargeLogs.vue')
            },
            {
                name: 'credit',
                title: '货款账户',
                path: '/credit',
                meta: { title: '货款账户' },
                component: () => import('../views/Credit.vue')
            }
        ]
    },
    {
        path: '/income',
        name: 'shouyi',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'income',
                title: '收益账户',
                path: '/income',
                meta: { title: '收益账户' },
                component: () => import('../views/Income.vue')
            },
            {
                name: 'incomeaccount',
                title: '提现账户管理',
                path: '/income/account',
                meta: { title: '提现账户管理' },
                component: () => import('../views/IncomeAccount.vue')
            },
            {
                name: 'incomeaccountedit',
                title: '提现账户编辑',
                path: '/income/account/edit',
                meta: { title: '提现账户管理' },
                component: () => import('../views/IncomeAccountEdit.vue')
            },
            {
                name: 'incomewithdraw',
                title: '提现',
                path: '/income/withdraw',
                meta: { title: '提现' },
                component: () => import('../views/IncomeWithdraw.vue')
            },
            {
                name: 'incomewithdrawlogs',
                title: '提现明细',
                path: '/income/withdrawlogs',
                meta: { title: '提现明细' },
                component: () => import('../views/IncomeWithdrawLogs.vue')
            },
            {
                name: 'incometoberecorded',
                title: '待结算',
                path: '/income/toberecorded',
                meta: { title: '待结算' },
                component: () => import('../views/IncomeToBeRecorded.vue')
            },
            {
                name: 'incomelogs',
                title: '收益明细',
                path: '/income/logs',
                meta: { title: '收益明细' },
                component: () => import('../views/IncomeLogs.vue')
            }
        ]
    },
    {
        path: '/announce',
        name: 'notice',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'announce',
                title: '平台公告',
                path: '/announce',
                meta: { title: '平台公告' },
                component: () => import('../views/Announce.vue')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router
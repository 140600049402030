//获取cookie、
export function getCookie(name) {
	var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
	arr = document.cookie.match(reg);
	if (arr)
		return unescape(arr[2]);
	else
		return false;
}

//设置cookie,增加到vue实例方便全局调用
export function setCookie(c_name, value, expiredays) {
	var exdate = new Date();
	exdate.setDate(exdate.getDate() + expiredays);
	document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
}

//删除cookie
export function delCookie(name) {
	var exp = new Date();
	exp.setTime(exp.getTime() - 1);
	var cval = getCookie(name);
	if (!cval)
		document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
}

export function getUUID() {
	var d = new Date().getTime();
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	});
	return uuid;
}

export function resizeImage(url, maxwidth = 800, maxheight = 800, opt = 'contain') {
	var img = new Image(); //提供给canvas使用
	img.src = url;
	return new Promise((resolve, reject) => {
		img.onload = (image) => {
			console.log(image)
			var canvas = document.createElement('canvas');
			let context = canvas.getContext('2d');

			var img_width = maxwidth,
				img_height = maxheight;
			if (opt == 'contain') {
				if (img.width * img_height >= img.height * img_width) {
					if (img.width > img_width) {
						//需要缩放
						img_height = (img.height * img_width) / img.width;
					} else {
						resolve(url);
						return;
					}
				} else {
					if (img.height > img_height) {
						//需要缩放
						img_width = (img.width * img_height) / img.height;
					} else {
						resolve(url);
						return;
					}
				}
			}
			console.log(img_width + '---' + img_height);

			canvas.width = img_width;
			canvas.height = img_height;
			context.drawImage(img, 0, 0, img_width, img_height);
			let dataret = canvas.toDataURL('image/jpeg');

			//console.log(dataret);

			resolve(dataret);
		}

		img.onerror = (image) => {
			console.log(image)
			reject(img);
		}
	});
}

export async function doResizeImage(url, maxwidth = 800, maxheight = 800, opt = 'contain') {
	let retdata = await resizeImage(url, maxwidth, maxheight, opt);

	return retdata;
}

export function getIsWxClient() {
	var ua = navigator.userAgent.toLowerCase();
	if (ua.match(/MicroMessenger/i) == "micromessenger") {
		return true;
	}
	return false;
}

export function readFileContent(file, resultType) {
	return new Promise((resolve) => {
		if (resultType === 'file') {
			resolve();
			return;
		}

		const reader = new FileReader();

		reader.onload = (event) => {
			resolve(event.target.result);
		};

		if (resultType === 'dataUrl') {
			reader.readAsDataURL(file);
		} else if (resultType === 'text') {
			reader.readAsText(file);
		}
	});
}

//获取QueryString的数组
export function getQueryString() {
	var result = location.search.match(new RegExp("[?&][^?&]+=[^?&]+", "g"));
	if (result == null) {
		return "";
	}
	for (var i = 0; i < result.length; i++) {

		result[i] = result[i].substring(1);

	}
	return result;
}

//根据QueryString参数名称获取值
export function getQueryStringByName(name) {
	var result = location.search.match(new RegExp("[?&]" + name + "=([^&]+)", "i"));
	if (result == null || result.length < 1) {
		return "";
	}
	return result[1];
}

//根据QueryString参数索引获取值
export function getQueryStringByIndex(index) {
	if (index == null) {
		return "";
	}
	var queryStringList = getQueryString();
	if (index >= queryStringList.length) {
		return "";
	}
	var result = queryStringList[index];
	var startIndex = result.indexOf("=") + 1;
	result = result.substring(startIndex);
	return result;
}

/**
 * 
 * @param {*} props 
 * @param {*} params 
 * @param {*} selects 
 * @returns 用来生成选择项的属性
 */
export function updatePropBoard(props, params, selects) {
	let propboard = [];

	for (var i = 0; i < props.length; ++i) {
		//先计算出该层级enabled的元素

		let newparams = params.filter((item => {
			if (item[item.length - 3] * 1 <= 0)	//没库存
				return false;

			for (k = 0; k < selects.length; ++k) {
				if (selects[k] && k != i) {
					if (selects[k] != item[k + 2])
						return false;
				}
			}

			return true;
		}));

		let item = {};

		item.PropName = props[i].PropName;

		item.options = [];

		//console.log(i, newparams);

		for (var k = 0; k < props[i].options.length; ++k) {
			let findone = newparams.find((paitem) => {
				//console.log(i, k, paitem[i + 2], props[i].options[k]);
				if (paitem[i + 2] == props[i].options[k])
					return true;
				return false;
			});

			item.options.push({
				name: props[i].options[k],
				selected: ((selects && selects[i] == props[i].options[k]) ? true : false),
				enabled: (findone ? true : false),
			});
		}

		propboard.push(item);
	}

	return propboard;
}

export function formatDateFull(date) {
	var datetime = new Date(date * 1000);
	var year = datetime.getFullYear();
	var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
	var day = ("0" + datetime.getDate()).slice(-2);
	var hour = ("0" + datetime.getHours()).slice(-2);
	var minute = ("0" + datetime.getMinutes()).slice(-2);
	var second = ("0" + datetime.getSeconds()).slice(-2);

	return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
}

export function formatDate(date) {
	var curdate = new Date();
	var curyear = curdate.getFullYear();
	var curmonth = ("0" + (curdate.getMonth() + 1)).slice(-2);
	var curday = ("0" + curdate.getDate()).slice(-2);


	var datetime = new Date(date * 1000);
	var year = datetime.getFullYear();
	var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
	var day = ("0" + datetime.getDate()).slice(-2);
	var hour = ("0" + datetime.getHours()).slice(-2);
	var minute = ("0" + datetime.getMinutes()).slice(-2);
	var second = ("0" + datetime.getSeconds()).slice(-2);

	var retdtime = '';

	if (curyear == year && curmonth == month && curday == day) {
		retdtime = hour + ":" + minute + ":" + second;
	} else {
		retdtime = year + "-" + month + "-" + day;
	}

	return retdtime;    //year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
}

export function formatMoney(money) {
	if (!money) return "0.00";
	let integer = Math.floor(money).toString();
	let thousands = integer
		.split("")
		.reverse()
		.join("")
		.replace(/(\d{3})/g, "$1.")
		.replace(/,$/, "");

	if (thousands.substr(-1, 1) == ".") {
		thousands = thousands.substring(0, thousands.length - 1);
	}

	return thousands.split("").reverse().join("");
}